import React, { useState, useEffect, useRef } from 'react';
import { Tabs, Input, Button, Space, Layout, Typography, message, Spin, Tag, Select } from 'antd';
import { PlayCircleOutlined, ReloadOutlined, PlusOutlined, MinusCircleOutlined, CopyOutlined } from '@ant-design/icons';
import Editor from 'react-simple-code-editor';
import Prism from 'prismjs';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-json';
import 'prismjs/components/prism-markup';
import 'prismjs/themes/prism.css';
import axios from 'axios';
import { useAuth0 } from "@auth0/auth0-react";
import styled from "styled-components";

const { TabPane } = Tabs;
const { Content } = Layout;
const { Title } = Typography;
const { Option } = Select;

const StyledContent = styled.div`
  margin: 24px 16px;
  padding: 24px;
  background: #fff;
  height: calc(100vh - 70px - 32px);
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const StyledTabContent = styled.div`
  flex: 1;
  overflow: hidden;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
`;

const ScrollableContent = styled.div`
  height: 400px;
  overflow-y: auto;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
`;

const EmailIframeWrapper = styled(ScrollableContent)`
  display: flex;
  flex-direction: column;
`;

const EmailIframe = styled.iframe`
  flex: 1;
  width: 100%;
  border: none;
`;

const TagsWrapper = styled(ScrollableContent)`
  padding: 16px;
`;

const TagExpressionInput = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  background-color: #f0f2f5;
  border-radius: 4px;
  padding: 4px;
`;

const StyledEditor = styled(Editor)`
  flex-grow: 1;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  padding: 4px 11px;
  background-color: white;
`;

const TaggingContainer = styled.div`
  background-color: #f0f2f5;
  padding: 16px;
  border-radius: 4px;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const CallEvaluationView = () => {
    const [transcript, setTranscript] = useState('');
    const [aiQuestions, setAiQuestions] = useState('');
    const [emailTemplate, setEmailTemplate] = useState('');
    const [tagExpressions, setTagExpressions] = useState(['']);
    const [answers, setAnswers] = useState('');
    const [emailHtml, setEmailHtml] = useState('');
    const [tags, setTags] = useState([]);
    const [activeTab, setActiveTab] = useState('input');
    const [loading, setLoading] = useState(false);
    const [initializing, setInitializing] = useState(true);
    const [tenants, setTenants] = useState([]);
    const [selectedTenant, setSelectedTenant] = useState('');
    const iframeRef = useRef(null);

    const { getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        fetchTenants();
    }, []);

    useEffect(() => {
        if (selectedTenant) {
            fetchStarterValues();
        }
    }, [selectedTenant]);

    useEffect(() => {
        if (iframeRef.current && emailHtml) {
            const iframe = iframeRef.current;
            iframe.src = 'about:blank';

            setTimeout(() => {
                iframe.src = `data:text/html;charset=utf-8,${encodeURIComponent(emailHtml)}`;
            }, 0);
        }
    }, [emailHtml]);

    const fetchTenants = async () => {
        try {
            const token = await getAccessTokenSilently({
                audience: "https://audience.auth0.leadlensai.com",
                scope: "openid profile email",
            });
            const response = await axios.get('/api/tenants/list', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setTenants(response.data);
            if (response.data.length > 0) {
                setSelectedTenant(response.data[0]);
            }
        } catch (error) {
            message.error('Error fetching tenants: ' + error.message);
        }
    };

    const fetchStarterValues = async () => {
        setInitializing(true);
        try {
            const token = await getAccessTokenSilently({
                audience: "https://audience.auth0.leadlensai.com",
                scope: "openid profile email",
            });
            const response = await axios.get('/api/evaluate-call-templates', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: { tenant: selectedTenant },
            });
            setTranscript(response.data.sampleTranscript);
            setAiQuestions(JSON.stringify(response.data.sampleAIQuestions, null, 2));
            setEmailTemplate(response.data.sampleEmailTemplate);
            setTagExpressions(response.data.sampleTagExpressions.split(','));
        } catch (error) {
            message.error('Error fetching starter values: ' + error.message);
        } finally {
            setInitializing(false);
        }
    };

    const handleRun = async () => {
        setLoading(true);
        try {
            const token = await getAccessTokenSilently({
                audience: "https://audience.auth0.leadlensai.com",
                scope: "openid profile email",
            });
            const response = await axios.post('/api/evaluate-call', {
                transcript,
                aiQuestions,
                emailTemplate,
                tagExpressions: tagExpressions.join(','),
                tenant: selectedTenant,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setAnswers(JSON.stringify(response.data.answers, null, 2));
            setEmailHtml(response.data.email);
            setTags(Array.isArray(response.data.tags) ? response.data.tags : []);
            setActiveTab('output');
            if(transcript && response.data.transcript && transcript.indexOf(' ') == -1)
                setTranscript(response.data.transcript);
        } catch (error) {
            message.error('Error evaluating call: ' + error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleTagExpressionChange = (index, value) => {
        const newExpressions = [...tagExpressions];
        newExpressions[index] = value;
        setTagExpressions(newExpressions);
    };

    const addTagExpression = () => {
        setTagExpressions([...tagExpressions, '']);
    };

    const removeTagExpression = (index) => {
        const newExpressions = tagExpressions.filter((_, i) => i !== index);
        setTagExpressions(newExpressions);
    };

    const highlightWithoutLineNumbers = (input, language) => {
        return Prism.highlight(input, Prism.languages[language], language);
    };

    const copyTagExpressions = () => {
        const expressionsText = tagExpressions.join(',');
        navigator.clipboard.writeText(expressionsText).then(() => {
            message.success('Tag expressions copied to clipboard');
        }, () => {
            message.error('Failed to copy tag expressions');
        });
    };

    const editorStyle = {
        fontFamily: '"Fira code", "Fira Mono", monospace',
        fontSize: 14,
        width: '100%',
        minHeight: '100%',
        backgroundColor: '#ffffff',
        lineHeight: '1.5',
    };

    const renderInputTabs = () => (
        <Tabs defaultActiveKey="1">
            <TabPane tab="Test Transcript" key="1">
                <ScrollableContent>
                    <Input.TextArea
                        value={transcript}
                        onChange={(e) => setTranscript(e.target.value)}
                        placeholder="Paste or type call transcript here"
                        style={{ ...editorStyle, resize: 'none', border: 'none' }}
                    />
                </ScrollableContent>
            </TabPane>
            <TabPane tab="JSON AI Questions" key="2">
                <ScrollableContent>
                    <Editor
                        value={aiQuestions}
                        onValueChange={code => setAiQuestions(code)}
                        highlight={code => highlightWithoutLineNumbers(code, 'json')}
                        style={editorStyle}
                        padding={10}
                    />
                </ScrollableContent>
            </TabPane>
            <TabPane tab="Email Template" key="3">
                <ScrollableContent>
                    <Editor
                        value={emailTemplate}
                        onValueChange={code => setEmailTemplate(code)}
                        highlight={code => highlightWithoutLineNumbers(code, 'markup')}
                        style={editorStyle}
                        padding={10}
                    />
                </ScrollableContent>
            </TabPane>
            <TabPane tab="Tagging" key="4">
                <ScrollableContent>
                    <TaggingContainer>
                        {tagExpressions.map((expression, index) => (
                            <TagExpressionInput key={index}>
                                <StyledEditor
                                    value={expression}
                                    onValueChange={(code) => handleTagExpressionChange(index, code)}
                                    highlight={code => highlightWithoutLineNumbers(code, 'javascript')}
                                    padding={10}
                                    style={{
                                        fontFamily: '"Fira code", "Fira Mono", monospace',
                                        fontSize: 14,
                                    }}
                                />
                                <Button
                                    type="text"
                                    icon={<MinusCircleOutlined />}
                                    onClick={() => removeTagExpression(index)}
                                    style={{ marginLeft: 8 }}
                                />
                            </TagExpressionInput>
                        ))}
                        <Space style={{ marginTop: 16, width: '100%', justifyContent: 'space-between' }}>
                            <Button
                                type="dashed"
                                onClick={addTagExpression}
                                icon={<PlusOutlined />}
                            >
                                Add Tag Expression
                            </Button>
                            <Button
                                type="primary"
                                icon={<CopyOutlined />}
                                onClick={copyTagExpressions}
                            >
                                Copy Expressions
                            </Button>
                        </Space>
                    </TaggingContainer>
                </ScrollableContent>
            </TabPane>
        </Tabs>
    );

    const renderOutputTabs = () => (
        <Tabs defaultActiveKey="1">
            <TabPane tab="AI Answers" key="1">
                <ScrollableContent>
                    <Editor
                        value={answers}
                        onValueChange={() => {}}
                        highlight={code => highlightWithoutLineNumbers(code, 'json')}
                        style={editorStyle}
                        padding={10}
                        readOnly={true}
                    />
                </ScrollableContent>
            </TabPane>
            <TabPane tab="Rendered Email" key="2">
                <EmailIframeWrapper>
                    {loading ? (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <Spin size="large" />
                        </div>
                    ) : (
                        <EmailIframe
                            ref={iframeRef}
                            title="Rendered Email"
                            sandbox="allow-scripts allow-same-origin"
                        />
                    )}
                </EmailIframeWrapper>
            </TabPane>
            <TabPane tab="Tags" key="3">
                <TagsWrapper>
                    {Array.isArray(tags) && tags.length > 0 ? (
                        tags.map((tag, index) => (
                            <Tag key={index} color="blue" style={{ margin: '0 8px 8px 0' }}>
                                {tag}
                            </Tag>
                        ))
                    ) : (
                        <p>No tags available</p>
                    )}
                </TagsWrapper>
            </TabPane>
        </Tabs>
    );

    if (initializing) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <Spin size="large" />
            </div>
        );
    }

    return (
        <Layout style={{ height: '100%', overflow: 'hidden' }}>
            <StyledContent>
                <HeaderContainer>
                    <Title level={3}>Configuration Testing</Title>
                    <Space>
                        <Typography.Text strong>Tenant:</Typography.Text>
                        <Select
                            style={{ width: 200 }}
                            value={selectedTenant}
                            onChange={(value) => setSelectedTenant(value)}
                            placeholder="Select a tenant"
                        >
                            {tenants.map((tenant) => (
                                <Option key={tenant} value={tenant}>{tenant}</Option>
                            ))}
                        </Select>
                    </Space>
                </HeaderContainer>
                <StyledTabContent>
                    <Tabs activeKey={activeTab} onChange={setActiveTab} style={{ height: '100%' }}>
                        <TabPane tab="Input" key="input" style={{ height: '100%' }}>
                            {renderInputTabs()}
                        </TabPane>
                        <TabPane tab="Output" key="output" style={{ height: '100%' }}>
                            {renderOutputTabs()}
                        </TabPane>
                    </Tabs>
                </StyledTabContent>
                <Space>
                    <Button
                        type="primary"
                        icon={<PlayCircleOutlined />}
                        onClick={handleRun}
                        loading={loading}
                        disabled={!selectedTenant}
                    >
                        Run Evaluation
                    </Button>
                    <Button
                        icon={<ReloadOutlined />}
                        onClick={fetchStarterValues}
                        disabled={!selectedTenant}
                    >
                        Revert Configuration
                    </Button>
                </Space>
            </StyledContent>
        </Layout>
    );
};

export default CallEvaluationView;
